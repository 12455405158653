import './App.css';

import {Routes, Route} from 'react-router-dom';
import SplashPage from './SplashPage.js';
import EditCheck from './EditCheck.js';
import PrintCheck from './PrintCheck.js';
import PrintCheckCB from './PrintCheckCB.js';
import ListChecks from './ListChecks.js';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SplashPage/>} />
        <Route path="/check" element={<EditCheck/>} />
        <Route path="/cb" element={<PrintCheckCB/>} />
        <Route path="/print" element={<PrintCheck/>} />
        <Route path="/list" element={<ListChecks/>} />
      </Routes>
    </div>
  );
}

export default App;
