import logo from './logo.svg';

export default function SplashPage() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <a
                className="App-link"
                href="https://papercheck.app"
                target="_blank"
                rel="noopener noreferrer"
            >
                PaperCheck.App
            </a>
            <h1>
                Print Paper Checks
            </h1>
        </header>
    );
}